import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { WishFromUsComponent } from './wish-from-us/wish-from-us.component';
import { CollaborateWithUsComponent } from './collaborate-with-us/collaborate-with-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageSectionComponent } from './components/page-section/page-section.component';
import { FooterComponent } from './footer/footer.component';
import { LastPageSectionComponent } from './components/last-page-section/last-page-section.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { HttpClientModule } from '@angular/common/http';
import { MatIconModule, MatInputModule, MatSlideToggleModule, MatButtonModule } from '@angular/material';
import { WishFormComponent } from './components/wish-form/wish-form.component';
import { FormsModule } from '@angular/forms';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    WishFromUsComponent,
    CollaborateWithUsComponent,
    ContactUsComponent,
    AboutUsComponent,
    PageNotFoundComponent,
    PageSectionComponent,
    FooterComponent,
    LastPageSectionComponent,
    WishFormComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    ScrollingModule,
    InlineSVGModule,
    AngularSvgIconModule,
    HttpClientModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    AngularFullpageModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
