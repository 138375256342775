import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WishFromUsComponent } from './wish-from-us/wish-from-us.component';
import { CollaborateWithUsComponent } from './collaborate-with-us/collaborate-with-us.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';


const routes: Routes = [
  { path: 'home', component: WishFromUsComponent, data: { animation: '' }   },
  { path: 'collaborate-with-us', component: CollaborateWithUsComponent, data: { animation: '' }  },
  { path: 'about-us', component: AboutUsComponent, data: { animation: '' }  },
  { path: 'contact-us', component: ContactUsComponent, data: { animation: '' }  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
