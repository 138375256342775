import { Component, OnInit } from '@angular/core';
import { Product } from '../model/product';
import { Router, NavigationStart } from '@angular/router';
// import { Location } from '@angular/common';
// import { ViewportRuler } from '@angular/cdk/scrolling'
// import { ScrollDispatcher } from '@angular/cdk/scrolling';

@Component({
  selector: 'app-wish-from-us',
  templateUrl: './wish-from-us.component.html',
  styleUrls: ['./wish-from-us.component.scss']
})
export class WishFromUsComponent implements OnInit {
  config: any;
  fullpage_api: any;
  public showScrollDownArrow: boolean = true;
  public isInitialState: boolean = true;
  public isFrontDisplayed: boolean = true;
  public isArrowsAnimated: boolean = false;
  public products: Product[] = [];
  public sectionsTexts: string[] = [
    "اوصف لنا حاجتك وارسلها لنا من خلال ملئ هذه الاستمارة....",
    "سوف تحصل بعد ذلك على قائمة بعدة مقترحات تلائم طلبك ....",
    "عندما تتاكد من وجود طلبك تحت رابط معين ارسل لنا الرابط المناسب على طلبك الذي ترغب به....",
    "بعد ذلك سوف نرسل لك معلومات وكيلنا الاقرب اليك، والتكاليف كاملة لشراء الطلب وارساله اليك، والمدة الزمنية التقديرية لوصول الطلب ....",
    "بعد الدفع سوف تحصل على بريد الكتروني يحوي تأكيد الدفع، وبعد ذلك سوف تصلك اشعارات تتضمن اخر المستجدات بخصوص طلبك ...."
  ];
  public activeSlideIndex: number = 0;
  public items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);

  constructor(private router: Router) {
      // for more details on config options please visit fullPage.js docs
      this.config = {

        // fullpage options
        licenseKey: 'E2C5819F-EF264E38-9FAE388A-D0A81452',
        anchors: ['firstPage', 'secondPage', 'thirdPage', 'fourthPage','fifthPage', 'sixthPage', 'seventhPage','lastPage'],
        sectionsColor : ['unset', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'],
        //scrollBar: true,
        //scrollingSpeed: 0,
        verticalCentered: true,
        lockAnchors: true,


        // fullpage callbacks
        afterResize: () => {
          console.log("After resize");
        },
        afterLoad: (origin, destination, direction) => {
          if (direction) {
            this.showScrollDownArrow = (origin.anchor === 'secondPage' && direction === 'up') ;
          }
          console.log('afterload ' + origin.anchor + 'destination ' + destination.toString() + 'direction ' + direction);
        }
      };
    this.router.events
      .subscribe(
        (event: NavigationStart) => {
          this.isInitialState = event.id === 1;
        }
      )
  }

    getRef(fullPageRef) {
      this.fullpage_api = fullPageRef;
    }

  ngOnInit() {

    let product2: Product = {
      imageSource: 'assets/products/back-strecher.png',
      brand: 'صحة',
      name: 'أدوات طبية',
      type: 'مشد ظهر',
      desc: 'بتقضي نهارك قاعد وبتعاني من آلام الظهر وبتتمنى لو في شي يحمل ظهرك عنك؟ طيب وصينا...'
    };

    let product3: Product = {
      imageSource: 'assets/products/hotel.png',
      brand: 'خدمات',
      name: 'حجوزات',
      type: 'فندق',
      desc: 'عندك دورة اسبوع ببلد أوروبي وبدك تحجز اوتيل قبل ما توصل؟ وصينا ونحنا شبيك لبيك'
    };


    let product: Product = {
      imageSource: 'assets/products/lippenstift.png',
      brand: 'جمال',
      name: 'مكياج',
      type: 'حمرة طويلة الامد',
      desc: 'محتاجة حمرة ما بدها تصحيح بعد الاكل وبتدوم 12 ساعة؟ وصينا ونحنا شبيك لبيك'
    };


    let product4: Product = {
      imageSource: 'assets/products/Question_mark_3d.png',
      //imageSource: 'https://i.imgur.com/ahLIrlv.png',
      brand: 'أي شي',
      name: 'أمنية خاصة',
      type: 'ما حدا بقدمها',
      desc: 'عندك طلب خاص غير متوفر بالأمثلة السابقة؟ وصفلنا طلبك ونحنا منساعدك تلاقي طلبك ومنوصلك ياه..!!'
    };

    this.products.push(product);
    this.products.push(product2);
    this.products.push(product3);
    this.products.push(product4);

    if (this.isInitialState) {
      setTimeout(() => {
        this.isInitialState = false;
      }, 2300);
    } else {
      this.isInitialState = false;
    }

  }

  nextSlide() {
    this.activeSlideIndex = (this.activeSlideIndex + 1) % this.products.length;
    this.flipModal();
  }

  previousSlide() {
    if (this.activeSlideIndex === 0) {
      this.activeSlideIndex = this.products.length - 1;
    } else {
      this.activeSlideIndex = this.activeSlideIndex - 1;
    }
    this.flipModal();
  }

  flipModal() {
    this.isArrowsAnimated = true;

    setTimeout(() => {
      this.isArrowsAnimated = false;
    }, 1500);

    this.isFrontDisplayed = !this.isFrontDisplayed;
  }

}


