import { Component, OnInit, Input, AfterViewInit, ElementRef } from '@angular/core';
import { Wish } from '../../model/wish';
import { ApiService } from '../../services/api.service'

@Component({
  selector: 'app-page-section',
  templateUrl: './page-section.component.html',
  styleUrls: ['./page-section.component.scss']
})
export class PageSectionComponent implements OnInit, AfterViewInit {

  public wish: Wish;
  public observer: IntersectionObserver;
  public inView: boolean = false;
  public isInitiated: boolean = false;
  public morphArrowTip: boolean = false;
  public revealDescription: boolean = false;
  public revealLogo: boolean = false;
  @Input() sectionId: string;
  @Input() height: number;
  @Input() text: string;
  @Input() even: boolean = false;
  @Input() logoPath: string = "";
  @Input() form: boolean = false;

  constructor(private _elementRef : ElementRef, private service: ApiService) { }

  ngOnInit() {
    this.wish = new Wish();
  }

  ngAfterViewInit() {
    const config = {
      threshold: [ 0]
    };
    this.isInitiated = true;
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0 && entry.isIntersecting && this.isInitiated) {
          this.inView = true;
          this.isInitiated = false;
          setTimeout(()=>{
            this.morphArrowTip = true;
            this.revealDescription = true;
            setTimeout(()=>{
              this.revealLogo = true;
            }, 400);
          }, 800);
        }
      });
    }, config);
    this.observer.observe(this._elementRef.nativeElement);
  }

  handleClick(wish: Wish) {
    this.service.createWish(wish).subscribe((data: {}) => {
          console.log(data)
      })
  }
}
