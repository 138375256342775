import { Component, ViewChild, ElementRef, Inject, AfterViewInit, HostListener } from '@angular/core';
import { slideInAnimation } from './animations';
import { RouterOutlet } from '@angular/router';
import { TweenLite } from 'gsap';
import { DOCUMENT } from '@angular/common';
declare module "gsap" {
  export interface TweenConfig {
    [p: string]: any;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation]
})
export class AppComponent implements AfterViewInit {
  title = 'Wasssina';
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;

  scroller;


  constructor(@Inject(DOCUMENT) private document: Document) {

  }

  getAnimationData(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData; // && outlet.activatedRouteData.animation;
  }

  ngAfterViewInit() {
    /*this.scroller = {
      target: this.scrollContainer.nativeElement,
      ease: 0.1, // <= scroll speed
      endY: 0,
      y: 0,
      resizeRequest: 1,
      scrollRequest: 0,
      requestId: null
    };
    TweenLite.to(this.scroller.target,1, {
      rotation: 0.01,
      force3D: true
    });
    let me = this;
    let  updateScroller = function() {
      let scroller = me.scroller;

      var resized = scroller.resizeRequest > 0;

      if (resized) {
        var height = scroller.target.clientHeight;
        document.body.style.height = height + "px";
        scroller.resizeRequest = 0;
      }

      var scrollY = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

      scroller.endY = scrollY;
      scroller.y += (scrollY - scroller.y) * scroller.ease;

      if (Math.abs(scrollY - scroller.y) < 0.05 || resized) {
        scroller.y = scrollY;
        scroller.scrollRequest = 0;
      }

      TweenLite.to(scroller.target, 1, {
        y: -scroller.y
      });

      scroller.requestId = scroller.scrollRequest > 0 ? requestAnimationFrame(updateScroller) : null;
    };

    let onScroll = function(event) {
      let scroller = me.scroller;
      scroller.scrollRequest++;
      console.log("scrolling events", event)
      if (!scroller.requestId) {
        scroller.requestId = requestAnimationFrame(updateScroller);
      }
    }
    let onResize = function() {
      let scroller = me.scroller;
      scroller.resizeRequest++;
      if (!scroller.requestId) {
        scroller.requestId = requestAnimationFrame(updateScroller);
      }
    }
    updateScroller();
    window.addEventListener("resize", onResize);
    document.addEventListener("scroll", onScroll);
    window.focus();*/
  }




}
