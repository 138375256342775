import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,  AfterViewInit {


  readyToBeShown = false;
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(()=>{  
          this.readyToBeShown = true;
    }, 100);

  }

  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }
}
