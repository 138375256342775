import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { gsap } from 'gsap';

@Component({
  selector: 'app-last-page-section',
  templateUrl: './last-page-section.component.html',
  styleUrls: ['./last-page-section.component.scss']
})
export class LastPageSectionComponent implements OnInit, AfterViewInit {

  @ViewChild("restQuote", { static: false }) private restQuate: ElementRef<HTMLElement>;

  private observer: IntersectionObserver;
  public inView: boolean = false;
  private isInitiated: boolean = false;



  constructor(private _elementRef: ElementRef) { }

  ngOnInit() {

  }

  ngAfterViewInit() {
    const config = {
      threshold: [0.8]
    };
    const words = this.restQuate.nativeElement.childNodes;
    let splitTextTimeline = gsap.timeline();
    this.isInitiated = true;
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0 && entry.isIntersecting && this.isInitiated) {
          this.inView = true;
          this.isInitiated = false;
          words.forEach((el, index) => {
            splitTextTimeline.from(el, { duration: 0.6, opacity: 0, force3D: true }, index * 0.05);
            splitTextTimeline.from(el, { duration: 1.2, y: 200, ease: "elastic" }, index * 0.05);
          });
          splitTextTimeline.play();
        }
      });
    }, config);
    this.observer.observe(this._elementRef.nativeElement);
  }
}
